import React, { useEffect, useState } from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import Button from 'src/components/Button';
import getUserData from 'src/utils/getUserData';
import Grid from 'src/components/Grid';
import HeroAccount from 'src/components/Hero/HeroAccount';
import inBrowser from 'src/utils/inBrowser';
import isLoggedIn from 'src/utils/isLoggedIn';
import Link from 'src/components/Link';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import ReportItem from 'src/components/Account/ReportItem';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import Text from 'src/components/Text';
import TextNumber from 'src/components/typography/TextNumber';
import theme from 'src/styles/theme';
import useAuth from 'src/hooks/useAuth';

const AccountSettingsPopup = loadable(() => import('../Account/AccountSettingsPopup'));

const MyReports = styled('div')`
    margin: 0 12px;
    padding: 16px 0;

    ${above.tablet_s}{
        margin: 0 32px;
    }
`;

const GridHeading = styled('div')`
    display: flex;

    margin: 0 12px;
    ${above.tablet_s}{
        margin: 0 32px;
    }
`;

const GridHeadingColumn = styled('div')`
    width: 100%;

    ${above.tablet_s}{
        width: 50%;

        padding: 0 0 0 16px;
        &:first-of-type {
            padding: 0 16px 0 0;
            border-right: 1px solid ${theme.color.black};
        }
    }

    ${below.tablet_s} {
        &:last-of-type {
            display: none;
        }
    }
`;

const GridHeadingTag = styled(Tag)`
    padding: 12px 0 12px 92px;

    ${above.tablet_s} {
        padding: 12px 0 12px 122px;
    }

    border-top: 1px solid ${theme.color.black};
    border-bottom: 1px solid ${theme.color.black};

    .noheading & {
        padding: 0;
        font-size: 0;
        line-height: 0;
        border-bottom: none;
    }
`;

const StyledGrid = styled(Grid)`
    margin: 0 12px 40px;

    ${above.tablet_s}{
        margin: 0 32px 88px;
    }
`;

const ReportItemWrapper = styled('div')`
    border-right: 1px solid ${theme.color.black};
    padding-right: 16px;

    &:nth-of-type(2n) {
        border-right: none;
        padding-right: 0;
        padding-left: 16px;
    }
`;

const EditSettings = styled(Tag)`
    text-decoration: underline;
    margin: 0 auto;
    margin-bottom: 64px;
    display: inline-block;
    cursor: pointer;
`;

const NoReportsWrapper = styled('div')`
    display: flex;
    align-items: center;
    flex-direction: column;

    ${theme.spacing.mobile._24('padding')};
    ${theme.spacing.tablet._32('padding')};
    ${theme.spacing.desktop._32('padding')};

    ${theme.spacing.mobile._40('padding-top')};
    ${theme.spacing.tablet._64('padding-top')};
    ${theme.spacing.desktop._88('padding-top')};

    ${theme.spacing.mobile._40('padding-bottom')};
    ${theme.spacing.tablet._64('padding-bottom')};
    ${theme.spacing.desktop._88('padding-bottom')};
`;

const InfoText = styled(Text)`
    text-align: center;
    line-height: 160%;

    ${theme.spacing.mobile._24('padding-bottom')};
    ${theme.spacing.tablet._24('padding-bottom')};
    ${theme.spacing.desktop._32('padding-bottom')};
`;

const StyledButton = styled(Button)`
    min-width: 221px;
    text-align: center;
`;

const compareReportDates = (a, b) => {
    const dateReportA = a.product?.date || 0;
    const dateReportB = b.product?.date || 0;

    if (dateReportA < dateReportB) {
        return 1;
    } else if (dateReportA > dateReportB) {
        return -1;
    }

    return 0;
};

const sortReportsByDate = reportsArray => {
    return reportsArray.sort(compareReportDates);
};

const Account = ({options}) => {
    const [userFirstName, setUserFirstName] = useState('');

    const refreshUserName = () => {
        setUserFirstName(isLoggedIn ? getUserData().name: '');
    };

    const { logout, fetchReports, downloadReport } = useAuth();
    const [displaySettings, setDisplaySettings] = useState(false);

    const displaySettingsPopup = () => {
        setDisplaySettings(!displaySettings);
    };

    if (inBrowser && !isLoggedIn) {
        window.location.href = '/login';
    }

    const [reports, setReports] = useState([]);
    useEffect(() => {
        refreshUserName();
        fetchReports().then(result => setReports(sortReportsByDate(result.data)));
    }, []);

    const settingsPopup = options.myPages.settingsPopup || null;

    const popupHeading = settingsPopup && settingsPopup.heading || '';
    const popupText = settingsPopup && settingsPopup.text || null;

    const heroText = options.myPages.heroSettings.text || '';

    return (
        isLoggedIn === true &&
        <>
            <HeroAccount logout={logout} data={{heading: `Welcome ${userFirstName}!`, text: heroText}}/>
            <div style={{textAlign: 'center'}}>
                <EditSettings onClick={() => displaySettingsPopup()}>Edit personal settings</EditSettings>
            </div>

            <MyReports>
                <TextNumber number={reports.length.toString()}>My reports</TextNumber>
            </MyReports>

            <GridHeading>
                <GridHeadingColumn>
                    <GridHeadingTag>Title & License</GridHeadingTag>
                </GridHeadingColumn>
                <GridHeadingColumn className={`${reports.length === 1 && 'noheading'}`}>
                    <GridHeadingTag>Title & License</GridHeadingTag>
                </GridHeadingColumn>
            </GridHeading>
            {reports.length > 0 ? (
                    <StyledGrid columns={2}>
                        {reports.map((report, index) => {
                            return (
                                report.product &&
                                    <ReportItemWrapper key={index}>
                                        <ReportItem
                                            product={report.product}
                                            edition={report.edition}
                                            licenseText={report.pivot && report.pivot.license_type}
                                            downloadHandler={() => downloadReport(report)}
                                        />
                                    </ReportItemWrapper>
                            );
                        })}
                    </StyledGrid>
                ) : (
                    <NoReportsWrapper>
                        <InfoText>No reports to show yet. Make a purchase while being logged in to access your reports here.</InfoText>
                        <Link src="/all-reports">
                            <StyledButton>See all reports</StyledButton>
                        </Link>
                    </NoReportsWrapper>
            )}

            {displaySettings &&
                <AccountSettingsPopup heading={popupHeading} toggleDisplay={setDisplaySettings} popupText={popupText} updateName={setUserFirstName} />
            }
        </>
    );
};

export default Account;

Account.propTypes = {
    options: PropTypes.shape({
        myPages: PropTypes.shape({
            heroSettings: PropTypes.shape({
                text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
            }),
            settingsPopup: PropTypes.shape({
                heading: PropTypes.string,
                text: PropTypes.string,
            }),
        }),
    }),
};

Account.defaultProps = {
    options: {
        myPages: {
            heroSettings: {
                text: '',
            },
        },
        settingsPopup: {
            heading: '',
            text: '',
        },
    },
};
